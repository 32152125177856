import React, { useLayoutEffect } from 'react';

interface CommentProps {
  commentElement?: 'span' | 'option';
  commentProp?: string;
  asString?: boolean;
}

/**
 * Component with all given prop combinations
 * @param propsDefinitions props and their combinations in arrays
 * @param Component
 * @param componentFunc modify props before given to the component, remember key (with index)
 * @param comment.commentElement what HTML element this should be if restricted to some
 * @param comment.commentProp if setting comment to other than children
 * @param comment.asString Use string not HTML-comment
 */
export const cartesianProps = <T extends { children?: React.ReactNode }>(
  propsDefinitions: { [P in keyof T]: T[P][] },
  Component: React.ElementType,
  componentFunc?: (props: T, index: number) => JSX.Element,
  comment?: CommentProps
) => {
  const { commentElement, commentProp, asString = false } = comment || {};
  const pairs = Object.keys(propsDefinitions).map((key) =>
    propsDefinitions[key as keyof T].map((value) => [key as keyof T, value])
  );
  const allPossibleProps = pairs
    .reduce(
      (acc, pair) => flattenArray(acc.map((x) => pair.map((y) => [...x, y]))),
      [[[]]] as unknown[][][]
    )
    .map((pairsArr) =>
      pairsArr.reduce(
        (result, [k, v]) => (!k ? result : { ...result, [k as string]: v }),
        {}
      )
    ) as T[];
  if (componentFunc)
    return allPossibleProps.map(({ children, ...props }, index) => {
      const propsAsString = `\n/** Component props were **/ : ${JSON.stringify(
        props,
        null,
        2
      ).trim()}`;
      const showProps = asString ? (
        propsAsString
      ) : (
        <HTMLComment
          text={propsAsString}
          element={commentElement}
        />
      );
      const useProps = commentProp
        ? { ...props, children, [commentProp]: showProps }
        : {
            ...props,
            children: (
              <>
                {children}
                {showProps}
              </>
            ),
          };
      return componentFunc(useProps as T, index);
    });
  return allPossibleProps.map(({ children, ...props }, index) => {
    const propsAsString = `\n/** Component props were **/ : ${JSON.stringify(
      props,
      null,
      2
    ).trim()}`;
    const showProps = asString ? (
      propsAsString
    ) : (
      <HTMLComment
        text={propsAsString}
        element={commentElement}
      />
    );
    const useProps = commentProp
      ? { ...props, children, [commentProp]: showProps }
      : {
          ...props,
          children: (
            <>
              {children}
              {showProps}
            </>
          ),
        };
    return (
      <Component
        key={index}
        {...(useProps as T)}
      />
    );
  });
};

function flattenArray<T>(arr: T[][]): T[] {
  return ([] as T[]).concat(...arr);
}

const HTMLComment = ({
  text,
  element: Element = 'span',
}: {
  text: string;
  element?: 'span' | 'option';
}) => {
  const ref = React.createRef<HTMLElement & HTMLOptionElement>();

  useLayoutEffect(() => {
    let el: HTMLElement | null = null;
    let parent: (Node & ParentNode) | null = null;
    let comm: Comment | null = null;

    if (ref.current) {
      el = ref.current;
      parent = el.parentNode;
      comm = document.createComment(` ${text} `);
      try {
        if (parent && parent.contains(el)) {
          parent.replaceChild(comm, el);
        }
      } catch (err) {
        console.error(err);
      }
    }

    return () => {
      if (parent && el && comm) {
        parent.replaceChild(el, comm);
      }
    };
  }, [ref, text]);

  return (
    <Element
      ref={ref}
      style={{ display: 'none' }}
    />
  );
};
