import React from 'react';
import {
  Heading as CHeading,
  HeadingProps as CHeadingProps,
  defineStyle,
  defineStyleConfig,
} from '@chakra-ui/react';
import { cartesianProps } from '../../utils/cartesian-props';

export const headingVariants = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;
type Variant = (typeof headingVariants)[number];

export interface HeadingProps extends CHeadingProps {
  /**
   * Heading level
   * <br/><b>h1 | h2 | h3 | h4 | h5 | h6</b>
   */
  variant: Variant;
}

export const headingTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 'semibold',
    lineHeight: 'shorter',
  },
  sizes: {
    h1: defineStyle({
      fontSize: ['3xl', null, null, '4xl'],
    }),
    h2: defineStyle({
      fontSize: ['2xl', null, null, '3xl'],
    }),
    h3: defineStyle({
      fontSize: ['xl', null, null, '2xl'],
    }),
    h4: defineStyle({
      fontSize: ['lg', null, null, 'xl'],
    }),
    h5: defineStyle({
      fontSize: ['md', null, null, 'lg'],
    }),
    h6: defineStyle({
      fontSize: ['sm', null, null, 'md'],
    }),
  },
}) as Record<string, unknown>;

export const Heading = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ variant = 'h1', as, ...props }, ref) => {
    return (
      <CHeading
        ref={ref}
        as={as ?? variant}
        size={variant}
        {...props}
      />
    );
  }
);

/**
 * For testing
 */

const components = cartesianProps<HeadingProps>(
  {
    variant: [...headingVariants],
    isTruncated: [true, false],
    margin: [3, undefined],
    padding: [5, undefined],
    children: ['Testing'],
  },
  Heading
);

export const toTesting = <>{components}</>;
